<template>
  <div>
    <label>
      <span class="btn btn-primary"> upload </span>
      <input
        type="file"
        id="selfie"
        name="selfie"
        accept="image/*;capture=camera"
        capture="user"
        class="d-none"
        @change="handleFileInput"
      />
    </label>
    <img :src="url" alt="" srcset="" width="100%" />
    <button @click="isOpenCam = !isOpenCam">open</button>
    <Camera :openCam="isOpenCam"></Camera>
  </div>
</template>

<style></style>

<script>
import Camera from "@/components/Camera.vue";
export default {
  components: {
    Camera,
  },
  data() {
    return { isOpenCam: false, file: null, url: null };
  },
  methods: {
    update(stream) {
      document.querySelector("video").src = stream.url;
    },
    handleFileInput(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      this.url = URL.createObjectURL(this.file);
    },
  },
};
</script>
